const baseURL = `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/p/api/invite/`;

export const getInvite = async (inviteId) => {
  const url = `${baseURL}${inviteId}`;
  const res = await fetch(url);
  const data = await res.json();
  return data;
};

export const clickInvite = async (inviteId) => {
  const url = `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/p/${inviteId}/click`;
  const res = await fetch(url);
  if (!res.ok) {
    throw "Invite not found";
  }
};

export const createFileUploadURL = async (inviteId, fileName) => {
  const url = `${process.env.REACT_APP_PLATFORM_API_BASE_URL}/p/api/invite/${inviteId}/file?filename=${fileName}`;
  const res = await fetch(url, {
    method: "POST",
  });
  const data = await res.json();
  return data;
};

export const uploadFile = async (uploadURL, imgFile) => {
  const res = await fetch(uploadURL, {
    method: "PUT",
    body: imgFile,
  });
};
