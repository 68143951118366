export const LinkExpired = () => {
  return (
    <div>
      <h1 className="text-xl">Page/Link Expired</h1>
      <p className="text-gray-600">
        <span id="expired-error">Sorry, the page/link that you're looking for has expired.</span>
      </p>
    </div>
  );
};
